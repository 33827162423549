.usp {
  background-color: rgba(73, 107, 230, 0.1);
  margin-top: 80px;
  padding-bottom: 60px;
}

.usp.simpleui {
  font-family: "Poppins", serif;
}

.titleHeader {
  text-align: center;
  padding: 4vh 0;

  color: #212121;
}

.titleHeader h2 {
  font-size: 28px;
  font-weight: 700;
}

.uspcardsList {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  width: 70vw;
  margin: 0 auto;
  row-gap: 4vh;
  column-gap: 3vw;
}

.uspCard {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 10px;
  align-items: center;
  border-radius: 20px;
  height: 380px;
  background-color: #fff;
  padding: 30px 2vw;
  position: relative;
}

.uspCard img {
  min-width: 6vw;
  max-width: 6vw;
  height: 6vw;
  object-fit: cover;
  margin-bottom: 15px;
}

.title {
  font-weight: 700;
  font-size: var(--card-title-font-size);
  color: #212121;
  margin: 0.5vw 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.desc {
  color: #757575;
  font-size: var(--card-desc-font-size);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.readMore {
  position: absolute;
  bottom: 25px;
  font-weight: 600;
  font-size: 14px;
  color: #496ce6;
  cursor: pointer;
}

.usp.simpleui .readMore svg {
  display: none;
}

/* EDGEFUSION */

.usp.edgefusion {
  margin-top: unset;
  font-family: "Inter", sans-serif;
  border-bottom: 1px solid #bdbdbd;
  background-color: unset;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 5vh;
}

.usp.edgefusion .titleHeader {
  /* font-size: 1.25vw; */
  padding: 5vh 0;
  font-style: italic;
}

.usp.edgefusion .uspcardsList {
  width: unset;
  grid-template-columns: repeat(3, 1fr);
}

.usp.edgefusion .uspCard {
  border-radius: unset;
  height: 100%;
}

.usp.edgefusion .title {
  /* font-size: 1.25vw; */
  margin-bottom: 1vw;
  font-style: italic;
  text-align: start;
}

.usp.edgefusion .desc {
  /* font-size: 0.9vw; */
  line-height: 1.3;
  margin-bottom: 2.5vw;
}

.usp.edgefusion .readMore {
  /* font-size: 1.05vw; */
  bottom: 9%;
  font-weight: 700;
}

@media screen and (max-width: 750px) {
  .uspcardsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: unset;
    gap: 20px;
  }

  .titleHeader {
    /* font-size: 20px; */
    margin: 0 2vw;
  }

  .uspCard {
    width: 90vw;
    height: 250px;
    margin: 0 auto;
  }

  .title {
    /* font-size: 22px; */
    font-weight: 600;
    margin: 0 3vw;
  }

  .desc {
    /* font-size: 18px; */
    color: #757575;
    font-weight: 400;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin: 0 2vw;
  }

  .uspCard img {
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  .readMore {
    bottom: 20px;
    font-size: 14px;
  }

  /* EDGEFUSION */

  .usp.edgefusion {
    width: unset;
    border: unset;
  }

  .usp.edgefusion .titleHeader {
    /* font-size: 20px; */
    padding-top: 0;
    font-style: normal;
  }

  .usp.edgefusion .uspCard {
    width: 90vw;
    align-items: flex-start;
    border-radius: 20px;
    padding: 20px 15px;
  }

  .usp.edgefusion .uspCard .title {
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 700;
    margin: 14px 0 8px;
  }

  .usp.edgefusion .uspCard .desc {
    /* font-size: 19px; */
    margin-bottom: 40px;
    text-align: start;
    margin-left: 0;
  }

  .usp.edgefusion .readMore {
    /* font-size: 16px; */
    bottom: 19px;
  }
}
