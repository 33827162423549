@media (max-width: 750px) {
  .menuModal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    margin: 0;
    padding: 0px;
    overflow: hidden;
    min-width: 100vw;
    min-height: 100vh;
  }

  .menuModal:focus-visible {
    outline: none;
  }

  .menuModal::backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.9;
    backdrop-filter: blur(15px);
  }

  .modalContainer {
    margin: 0px;
    border: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vh;
    margin: 0;
    padding: 0px;
    list-style: none;
    flex: 1;
  }

  .menuItems li {
    font-size: 30px;
    font-weight: 300;
    color: white;
  }

  .menuItems li:hover {
    color: #0c66e4;
  }

  .close {
    color: #fff;
    display: flex;
    /* align-items: flex-end; */
    justify-content: flex-end;
    margin-right: 3vh;
    margin-top: 3vh;
  }

  .closeBtn {
    font-size: 3.5vh;
    background-color: #fff;
    padding: 1vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 1px 0px 5px 1px #bdbdbd;
    border: none;
    cursor: pointer;
  }

  .closeBtn:hover {
    background-color: #ffffff99;
  }

  .closeBtn:focus {
    outline: none;
  }

  .bgBlue {
    border: none;
    background-color: #0c66e4;
    color: #fff;
    padding: 0.5vw 1.5vw;
    /* padding: 10px 30px; */
    font-size: 25px;
    border-radius: 50px;
    font-family: inherit;
    cursor: pointer;
    padding: 1.5vh 3vh;
    font-weight: 600;
    transition: 0.3s all ease;
  }

  .bgBlack {
    border: 5px solid #0c66e4;
    background-color: transparent;
    color: #fff;
    border-radius: 50px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 25px;
    padding: 1.5vh 6vh;
    font-family: inherit;
    margin-top: 2vh;
  }

  .bgBlack:hover {
    background-color: #0c66e4;
    /* border: 3px solid #fff; */
    color: white;
  }

  .bgBlue:hover {
    background-color: transparent;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  }

  /* .close:hover {
    background-color: #0c66e4d9;
    color: #ffffff99;
  } */
}
@media (min-width: 750px) {
  .menuModal {
    display: none;
  }
}
