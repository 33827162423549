.mobile-dashboard-invoices-list {
  padding: 0;
  margin-top: 2vw;
}

.mobile-dashboard-invoices-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4.3vw;
}
