.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  background-color: white;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-width: 30%;
  z-index: 12;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5vw 0;
  position: relative;
  color: #212121;
}

.closeBtn {
  position: absolute;
  top: 1.2vw;
  right: 1.25vw;
  font-size: 1.5vw;
  cursor: pointer;
}

.popupHeader {
  text-transform: uppercase;
  font-size: 1.35vw;
  font-weight: 700;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1vw;
  padding: 1.7vw 15%;
  gap: 0.5vw;
  font-size: 1.15vw;
  color: #212121;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.details img {
  height: 5.2vw;
  width: 5.2vw;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1vw;
}

.details .name {
  font-weight: 700;
  font-size: 1.3vw;
  margin-bottom: 0.2vw;
}

.signoutBtn {
  font-family: inherit;
  font-size: 0.95vw;
  font-weight: 600;
  text-transform: uppercase;
  color: white;

  background-color: #496ce6;
  padding: 0.6vw 2vw;
  border-radius: 50px;
  border: transparent;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .mainContainer {
    width: 85%;
    border-radius: 8px;
    padding: 4.8vw 0;
  }

  .closeBtn {
    top: 3.5vw;
    right: 3.7vw;
    font-size: 5vw;
  }

  .popupHeader {
    font-size: 4.3vw;
  }

  .details {
    margin: 4vw;
    padding: 4vw 18%;
    gap: 2vw;
    font-size: 3.5vw;
  }

  .details img {
    height: 13vw;
    width: 13vw;
  }

  .details .name {
    font-size: 4.2vw;
  }

  .signoutBtn {
    font-size: 3.35vw;
    padding: 2.2vw 4vw;
  }
}
