.appDetails {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
}

.header {
  display: flex;
  align-items: center;
  line-height: 0;
  font-size: 18.87px;
}

.appDetails .header img {
  width: 37px;
  height: 37px;
}

.appDetails .image img {
  width: 32vw;
  height: 16vw;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contactSales .title {
  font-weight: 700;
  font-size: 20px;
  color: #212121;
  margin-bottom: 20px;
  padding-left: 1vw;
}

.salesDetails {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #212121;
  margin-bottom: 10px;
  padding-left: 1vw;
}

.salesDetails .details {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}

/* MIDNIGHTZEN */

.appDetails.midnightzen .contactSales .title,
.appDetails.midnightzen .contactSales .salesDetails,
.appDetails.midnightzen .contactSales .salesDetails .details {
  color: #fff;
}

@media (max-width: 696px) and (min-width: 601px) {
  .appDetails .image img {
    width: 45vw;
  }
}
