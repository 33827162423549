.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.signIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-radius: 10px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 5px #bdbdbd;
  min-width: 45vw;
  min-height: 75vh;
}

.header {
  font-family: "Poppins", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  color: #212121;
  font-size: 45px;
}

.header img {
  width: 55px;
  height: 55px;
  border-radius: 13px;
  object-fit: cover;
}

.image img {
  width: 382px;
  height: 304px;
  /* object-fit: fill; */
}

.description {
  text-align: center;
  font-weight: 400;
  font-size: 21px;
  color: #4d4d4d;
}

.button {
  display: flex;
  align-items: center;
  gap: 0.4vw;
  background-color: #1977e4;
  padding: 4px 35px 4px 4px;
  border-radius: 50px;
  font-size: 18.6px;
  font-weight: 300;
  color: #fff;
  height: 52px;
  margin-bottom: 5%;
}

.button:hover {
  cursor: pointer;
}

.button img {
  /* width: 35px; */
  height: 100%;
  border-radius: 50%;
  padding: 6px;
  background-color: #fff;
  margin-right: 3px;
}

.errorText {
  color: #ef5350;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 750px) {
  .signIn {
    margin: 5vh 5vw 0;
    width: 100%;
    height: 100%;
    min-width: unset;
    min-height: unset;
    box-shadow: unset;
    gap: unset;
    justify-content: start;
  }

  .container {
    background-color: #fff;
    height: unset;
  }

  .header {
    font-size: 36px;
    gap: 8px;
  }

  .header img {
    width: 50px;
    height: 50px;
  }

  .image img {
    width: 90vw;
    height: 32vh;
    margin-top: 5vh;
    object-fit: contain;
  }

  .description {
    /* width: 90%; */
    font-size: 21px;
    font-weight: 500;
    color: #000;
    margin-top: 6vh;
  }

  .button {
    margin-top: 2.5vh;
    font-size: 20px;
    font-weight: 400;
    height: 60px;
  }

  .button img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .errorText {
    margin: 3vh 0;
    font-size: 20px;
    font-weight: 400;
  }
}
