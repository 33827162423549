.testimonials {
  margin-top: 2vh;
  background-color: rgba(73, 107, 230, 0.1);
  padding-bottom: 4vh;
}

.titleHeader {
  text-align: center;
  padding: 4vh 0;
}

.titleHeader h2 {
  /* font-size: 28px; */
  font-size: var(--section-title-font-size);
  font-weight: 700;
}

.testimonials.simpleui {
  font-family: "Poppins", serif;
}

.testimonialsCardList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70vw;
  margin: 0 auto;
  row-gap: 2vw;
  column-gap: 2vw;
}

.testimonials.testimonialCard {
  background-color: #fff;
}

.testimonialCard {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  height: 370px;
  border-radius: 40px;
  padding-left: 2vw;
  padding-right: 20px;
  overflow: hidden;
  max-width: 34vw;
  position: relative;
}

.testimonialCard .title .quotation {
  font-size: 54px;
  margin-right: 1.5vw;
  transform: translateY(30px);
  position: relative;
  top: 18px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  font-size: var(--card-title-font-size);
  color: #212121;
  margin: 10px 0 15px;

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.desc {
  color: #757575;
  /* font-size: 18px; */
  font-size: var(--card-desc-font-size);
  /* text-align: center; */
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.studentInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 40px;
}

.details {
  display: inline;
}

.imgRounded {
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  object-fit: cover;
}

.name {
  font-size: 22px;
  color: #212121;
  font-weight: 600;
}

.testimonials.simpleui .quotation {
  color: #496ce6;
}

@media screen and (max-width: 750px) {
  .titleHeader {
    /* font-size: 20px; */
    padding-bottom: 2vh;
  }

  .testimonialsCardList {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .testimonialCard {
    width: 90vw;
    border-radius: 10px;
    box-shadow: unset;
    padding-left: 22px;
    height: fit-content;
    max-width: unset;
  }

  .title {
    padding: 0px;
    /* font-size: 16px; */
    margin: 0;
  }

  .desc {
    /* font-size: 15px; */
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    -webkit-box-orient: vertical;
    color: #757575;
    font-weight: 400;
    margin-bottom: 80px;
    padding-top: 20px;
  }

  .studentInfo {
    gap: 20px;
    bottom: 20px;
  }

  .imgRounded {
    width: 50px;
    height: 50px;
  }

  .name {
    font-size: 17px;
  }

  .testimonialCard .title {
    /* width: 90%; */
    margin-top: 16px;
  }

  .testimonialCard .title .quotation {
    font-size: 40px;
    position: absolute;
    top: 5px;
    /* position: unset; */
    transform: translate(0);
  }

  .testimonialCard .title .textBold {
    /* position: absolute; */
    margin-left: 50px;
    width: 100%;
  }

  .testimonials .desc {
    position: relative;
    top: -10px;
  }
}
