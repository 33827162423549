@media (max-width: 750px) {
  .menuModalDialog {
    min-width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    animation: slideIn 0.3s ease-out forwards;
  }

  .menuModalDialog.closing {
    animation: slideOut 0.3s ease-out forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  .menuModalList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    color: #fff;
    /* height: 85vh; */
    font-size: 2.5vh;
    font-weight: 300;
    padding-inline-start: 0;
    gap: 2.5vh;
    cursor: pointer;
    margin-top: 10px;
  }

  .menuModalList a {
    color: #fff;
  }

  .menuModalDialog .closeButton {
    padding-right: 0;
    margin-top: 2vh;
    margin-right: 2vh;
    cursor: pointer;
  }

  .menuModalDialog .closeButton .close {
    position: relative;
    left: 90%;
    color: #212121;
    border: none;
    padding: 7px;
    font-size: 25px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    border-radius: 50%;
  }

  .menuModalDialog .closeButton .close:hover {
    opacity: 0.5;
  }

  .closeButton .close:focus {
    outline: none;
  }

  .actionButton button {
    color: #fff;
    padding: 15px 50px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50px;
    font-size: 2.5vh;
    font-family: inherit;
    cursor: pointer;
  }

  .actionButton button:hover {
    /* background-color: #0c66e499; */
    opacity: 0.5;
  }

  .call {
    font-style: italic;
    font-size: 3vw;
    text-align: center;
    padding-top: 1vh 0;
    font-weight: 300;
  }

  .menuItem:hover {
    /* color: #0c66e4; */
    opacity: 0.5;
  }

  .call span {
    display: block;
    font-weight: 500;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-top: 1vh;
    gap: 1vh;
  }
}

/* MIDNIGHTZEN */

.menuModalDialog.midnightzen {
  font-family: "Nunito", sans-serif;
  background-color: rgba(0, 0, 0, 0.85);
}

.menuModalDialog.midnightzen .menuModalList {
  font-size: 22px;
  justify-content: center;
}

.menuModalDialog.midnightzen .closeButton .close {
  color: #fff;
  background-color: #424242;
}

/* ELEVATE */

.menuModalDialog.elevate {
  font-family: "Nunito Sans", serif;
}

.menuModalDialog.elevate .actionButton button {
  border-radius: 10px;
}

.menuModalDialog.elevate .call {
  font-size: 4vw;
}

.menuModalDialog.elevate .call span {
  font-size: 2.5vh;
  border-radius: 10px;
}

/* EDGEFUSION */

.menuModalDialog.edgefusion {
  font-family: "Inter", sans-serif;
}

.menuModalDialog.edgefusion .actionButton button {
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  border-radius: unset;
  font-style: italic;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 100px;
}

.menuModalDialog.edgefusion .menuItem {
  font-size: 26px;
}

.menuModalDialog.edgefusion .menuModalList {
  gap: 3vh;
}

.menuModalDialog.edgefusion .call {
  font-size: 20px;
  margin-top: 10px;
}

.menuModalDialog.edgefusion .call span {
  padding: 15px 10px;
  border-radius: 30px;
  font-size: 22px;
}

/* MINIMALIST */

.menuModalDialog.minimalist {
  font-family: "Manrope", serif;
}

.menuModalDialog.minimalist .actionButton button {
  border-radius: unset;
}

@media screen and (min-width: 750px) {
  .menuModalDialog {
    display: none;
  }
}
