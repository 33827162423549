@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.services {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw 10%;
  width: 100%;
}

.services .titleHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 4vh;
}

.services h2 {
  /* font-size: 1.8vw; */
  font-size: var(--section-title-font-size);
  font-weight: bold;
  text-align: center;
}

.services .show-all {
  position: absolute;
  right: 0;
}

.services .no-show-all {
  display: none;
}

.services ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3vw;
  width: 80vw;
}

.serviceCard {
  border-radius: 20px;
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  /* width: 25vw; */
  max-width: 25.67vw;
  cursor: pointer;
  position: relative;
}

.serviceCard:hover {
  background-color: #f2eeee33;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.serviceCard img {
  object-fit: cover;
  width: 100%;
  height: 13vw;
  border-radius: 20px 20px 0 0;
}

.serviceCard .content {
  padding: 1.5vh 1.5vw 1.6vh 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  /* width: 20vw; */
}

.serviceCard .title {
  /* font-size: 1.3vw; */
  font-size: var(--card-title-font-size);
  font-weight: bold;
  color: #212121;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.serviceCard .desc {
  /* font-size: 1vw; */
  font-size: var(--card-desc-font-size);
  color: #757575;
  margin-top: 0.5vw;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* margin-bottom: 0.5vw; */
  margin-bottom: 30px;
}

.readMore {
  top: 89.5%;
  /* font-size: 1vw; */
  font-size: var(--card-desc-font-size);
  color: var(--primary-color);
  font-weight: bold;
  position: absolute;
  cursor: pointer;
}

/* MIDNIGHTZEN */

.services.midnightzen {
  background-color: #000;
  font-family: "Nunito Sans", serif;
  padding: 25px 10% 50px;
  border-bottom: 1px solid #757575;
}

.services.midnightzen .titleHeader {
  color: #fff;
}

.services.midnightzen .serviceCard {
  background-color: #212121;
}

.services.midnightzen .desc {
  color: #bbb;
}

.services.midnightzen .serviceCard img {
  height: 14vw;
}

.services.midnightzen .serviceCard .title {
  color: #fff;
}

.services.midnightzen .readMore {
  color: #fff;
}

/* ELEVATE */

.services.elevate {
  font-family: "Nunito", sans-serif;
  width: 80vw;
  padding: 0;
  padding-bottom: 5vw;
  margin: 2.5vw auto 0;
  border-bottom: 1px solid #bdbdbd;
}

.services.elevate ul {
  width: 80vw;
}

.services.elevate .serviceCard {
  border-radius: 1vw 1vw 0 0;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  /* max-width: 21.67vw; */
  width: unset;
}

.services.elevate .content {
  display: flex;
  padding: unset;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Nunito Sans", serif;
  /* width: 25vw; */
}

.services.elevate .serviceCard:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.services.elevate .serviceCard img {
  border-radius: 1vw 1vw 0 0;
}

.services.elevate .serviceCard .desc {
  padding: 0 1vw;
  /* font-size: 1.2vw; */
}

.services.elevate .content .title {
  text-align: center;
  justify-self: center;
  padding: 1vw 1vw 0.2vw;
  /* font-size: 1.2vw; */
}

.services.elevate .titleHeader {
  justify-content: start;
  margin-top: 2vw;
  margin-bottom: 3vw;
}

.services.elevate .readMore {
  background-color: #496ce6;
  min-width: 100%;
  max-width: 100%;
  margin: unset;
  position: absolute;
  align-self: flex-start;
  top: 100%;
  transform: translate(-50%, -100%);
  padding: 1vw;
  color: #fff;
  margin-top: 2.5vw;
  border-radius: 0 0 1vw 1vw;
  display: flex;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}

.services.elevate .readMore svg {
  display: none;
}

/* MINIMALIST */

.services.minimalist {
  font-family: "Manrope", serif;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 5vh;
  margin-bottom: 3.5vh;
}

.services.minimalist .titleHeader {
  justify-content: start;
  text-align: start;
  margin-top: 32px;
}

.serviceCard.minimalist {
  box-shadow: unset;
  border-radius: unset;
}

.services.minimalist .content {
  padding-left: unset;
}

.services.minimalist img {
  border-radius: unset;
}

.services.minimalist .readMore {
  color: #946199;
  text-decoration: underline;
}

.services.minimalist .readMore svg {
  display: none;
}

/* -------------------------------------------------------------------- */

@media screen and (max-width: 750px) {
  .services {
    padding: 3vh 4vw 0 4vw;
  }

  .services .titleHeader {
    margin-bottom: 0;
  }

  .services h2 {
    /* font-size: 3vh; */
    margin: 1vh 0 2.5vh 0;
  }

  .services .show-all {
    display: none;
  }

  .services ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: unset;
  }

  .serviceCard {
    height: fit-content;
    width: 90vw;
    max-width: unset;
  }

  .serviceCard img {
    height: 25vh;
    max-height: 25vh;
  }

  .serviceCard .content {
    padding: 1vh 2vh 2vh 2vh;
    height: 50%;
    width: 100%;
  }

  .serviceCard .title {
    margin: 0;
    /* font-size: 2.5vh; */
    margin-bottom: 1vh;
  }

  .serviceCard .desc {
    /* font-size: 2vh; */
    margin-bottom: 12px;
  }

  .serviceCard .readMore {
    position: unset;
    text-align: center;
    font-size: 14px;
  }

  /* MIDNIGHTZEN */

  .services.midnightzen {
    border-bottom: 2px solid #757575;
  }

  .services.midnightzen .readMore {
    font-size: 15px;
  }

  .services.midnightzen .serviceCard img {
    height: 20vh;
  }

  /* ELEVATE */

  .services.elevate {
    border-bottom: unset;
  }

  .services.elevate ul {
    width: 90vw;
  }

  .services.elevate .titleHeader {
    justify-content: center;
    margin: 3vh 0;
  }

  .services.elevate .serviceCard {
    border-radius: 15px 15px 0 0;
  }

  .services.elevate .readMore {
    /* min-width: 90vw;
    max-width: 90vw; */

    padding: 15px 0;
    border-radius: 0 0 15px 15px;
  }

  .services.elevate .serviceCard img {
    border-radius: 15px 15px 0 0;
  }

  .services.elevate .content {
    margin-bottom: 25px;
  }

  .services.elevate .serviceCard .title {
    font-size: 5.4vw;
    margin-bottom: 5px;
    padding: 10px 4vw 0;
  }

  .services.elevate .serviceCard .desc {
    font-size: 4.3vw;
    padding: 0 4vw;
    margin-bottom: 20px;
  }

  /* MINIMALIST */
  .services.minimalist .titleHeader {
    justify-content: center;
    text-align: center;
    margin-top: 0;
  }
}
