.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.modalHeaderWeb {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
  padding: 2.5vw;
}

.modalHeaderWeb .backNavigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.35vw;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
}

.modalHeaderWeb .backNavigation:hover {
  background-color: #f5f5f5cc;
}

.modalHeaderWeb .backArrow {
  font-size: 1.8vw;
  margin-right: 0.7vw;
  cursor: pointer;
}

.modalHeaderWeb .modalTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #0c66e4;
  font-size: 1.6vw;
}

.modalBody {
  flex: 1;
  padding: 0 3vw 3vw;
  overflow-y: auto;
}

@media screen and (max-width: 750px) {
  .modalHeaderMobile {
    display: flex;
    align-items: center;
    padding: 4vw;
    font-size: 6vw;
    font-weight: bold;
  }
  .modalHeaderMobile .backArrow {
    font-size: 6vw;
    cursor: pointer;
    margin-right: 16px;
    cursor: pointer;
  }
  .modalBody {
    padding: 0 3.2vw 3vw;
  }
}
