.member-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.member-dashboard .content-area {
  background-color: #f5f5f5;
  padding: 2.4vw 14vw;
}

.member-dashboard-wrapper .upcoming-classes-card,
.member-dashboard-wrapper .batches-wrapper-card,
.member-dashboard-wrapper .invoices-wrapper-card,
.member-dashboard-wrapper .contact-us-card {
  margin-bottom: 1.8vw;
  padding: 1.2vw 2vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* box-shadow: 0 0 6px #bdbdbd; */
  border-radius: 12px;
}

.member-dashboard-wrapper .card-title-bold {
  color: #212121;
  font-size: 1.25vw;
  font-weight: 700;
}

.member-dashboard-wrapper .title-show-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2vw;
}

.member-dashboard-wrapper .title-show-all .show-all {
  color: #496ce6;
  font-size: 1.1vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.member-dashboard-wrapper .title-show-all .arrow-icon {
  font-size: 1.5vw;
  margin-left: 0.25vw;
}

.member-dashboard-wrapper .contact-us-card span {
  margin: 0.5vw 0 0.8vw;
  color: #424242;
  font-size: 0.99vw;
  line-height: 1.5;
}

.member-dashboard-wrapper .contact-us-card .get-support-btn {
  font-family: inherit;
  color: white;
  background-color: #496ce6;
  border: none;
  font-size: 0.9vw;
  font-weight: 600;
  border-radius: 50px;
  padding: 0.6vw;
  max-width: 20%;
  cursor: pointer;
}

.member-dashboard-wrapper .contact-us-card .get-support-btn:hover {
  background-color: #496ce6d9;
}

.member-dashboard-wrapper .loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 50px 0;
}

/* .member-dashboard-wrapper .loader {
  border: 6px solid;
  border-color: rgb(72, 72, 72) transparent rgb(72, 72, 72) transparent;
  border-radius: 50%;
  width: 2.5vw;
  height: 2.5vw;
  animation: spin 1.2s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
} */

.mobile-member-dashboard {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.mobile-member-dashboard .contact-us-card span {
  margin: 1.5vw 0 3.2vw;
  color: #212121;
  font-size: 3.8vw;
}

.mobile-member-dashboard .contact-us-card .get-support-btn {
  font-family: inherit;
  color: white;
  background-color: #496ce6;
  border: none;
  font-size: 3.8vw;
  font-weight: 600;
  border-radius: 50px;
  padding: 2vw;
  max-width: 37%;
}

.member-dashboard .empty-list {
  font-size: 18px;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5vw;
}

@media screen and (max-width: 750px) {
  .content-area {
    background-color: #f5f5f5;
    padding: unset;
  }

  .member-dashboard-wrapper .upcoming-classes-card,
  .member-dashboard-wrapper .batches-wrapper-card,
  .member-dashboard-wrapper .invoices-wrapper-card,
  .member-dashboard-wrapper .contact-us-card {
    margin: 2vw 3vw 5vw;
    padding: 2.8vw 2.6vw;
    border-radius: 8px;
  }

  .member-dashboard-wrapper .title-show-all {
    margin-bottom: 3vw;
  }

  .member-dashboard-wrapper .card-title-bold {
    font-size: 4.7vw;
  }

  .member-dashboard-wrapper .title-show-all .show-all {
    font-size: 4.3vw;
  }

  .member-dashboard-wrapper .title-show-all .arrow-icon {
    font-size: 6.5vw;
    margin-left: unset;
  }

  .member-dashboard-wrapper .empty-list {
    font-size: 17px;
    color: #9e9e9e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
