.all-why-us {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 0px;
  overflow: auto;
  min-width: 100vw;
  min-height: 100vh;
}

.all-why-us-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  padding: 45px 9vw;
}

.all-why-us .back-btn {
  position: absolute;
  top: 40px;
  left: 40px;

  border: transparent;
  background-color: white;
  box-shadow: 0 0 9px #acabab;
  border-radius: 50%;

  height: 45px;
  width: 45px;

  color: #424242;
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #e6e3e366;
  color: black;
}

.all-why-us .title {
  font-size: 2.5rem;
  font-weight: bold;

  text-align: center;

  margin-bottom: 35px;
}

.all-why-us ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 60px;
}
