.invite-friends-card {
  margin-bottom: 1vw;
  padding: 1.2vw 2vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* box-shadow: 0 0 6px #bdbdbd; */
  border-radius: 12px;
}

.invite-friends-card .social-icons-row {
  margin-top: 1.2vw;
  display: flex;
  align-items: center;
  gap: 1.15vw;
}

.invite-friends-card .social-icon img {
  width: 2vw;
  height: 2vw;
  object-fit: contain;
}

.invite-friends-card .social-icon {
  background-color: #f5f5f5;
  padding: 2vw;
  height: 2.1vw;
  width: 2.1vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .invite-friends-card {
    margin: 1vw 3vw 3vw;
    padding: 3vw 2.6vw 4vw;
    border-radius: 8px;
  }

  .invite-friends-card .social-icons-row {
    margin-top: 3.8vw;
    gap: 3vw;
  }

  .invite-friends-card .social-icon {
    width: 5vw;
    height: 5vw;
    padding: 2vw;
  }

  .invite-friends-card .social-icon img {
    height: 3vw;
    width: 3vw;
  }
}
