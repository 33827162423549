.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #212121;
  color: white;
  /* height: 52vh; */
  margin-top: 0;
  padding: 3vw 0;
  width: 100%;
}

.footer .content {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%; */
  /* margin: 0 auto; */
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.footer .brand {
  margin-left: 6vw;
  margin-right: 20%;
}

.footer .brand-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin-bottom: 0.5vw;
  /* align-self: stretch; */
}

.footer .brand-logo img {
  height: 2.1vw;
  width: 2.1vw;
  object-fit: cover;
  margin-right: 0.7vw;
}

.footer .brand-logo .textBold {
  font-size: 2vw;
  font-weight: 600;
}

.textLight {
  font-size: 0.8vw;
  font-weight: 100;
}

.textMedium {
  font-weight: 600;
  margin-top: 2vw;
  font-size: 1.35vw;
}

.quickLinks {
  list-style: none;
  margin-top: 1vw;
}

.link {
  font-weight: 100;
  font-size: 0.9vw;
  margin-bottom: 1vw;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.link:hover {
  color: #1976d2;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  flex: 1;
  /* align-self: stretch; */

  margin-left: 20px;
  margin-right: 20%;
}

.address span {
  font-size: 1.25vw;
  font-weight: 600;
  margin-bottom: 8px;
}

.address pre {
  font-family: inherit;
  font-size: 1.19vw;
  font-weight: 300;
  line-height: 2;
  margin: 0px;
}

.address a {
  color: #1976d2;
  margin-top: 12px;
  font-size: 1.24vw;
}

.footerLinks {
  display: block;
}

.footerLinksMobile {
  display: none;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 20px;
  justify-self: flex-end;

  /* margin-left: 30px; */
  flex: 1;
  /* align-self: stretch; */

  color: white;
}

.social p {
  font-size: 1.3vw;
  font-weight: 600;
  margin: 0;
}

.socialIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.4vw;
  font-size: 2vw;
}
/* 
.socialIcons img {
  height: 3.6vw;
  width: 3.6vw;

  cursor: pointer;
} */

.poweredBy {
  font-size: 1.15vw;
  color: #ffffffcc;
  font-weight: 400;
  align-self: center;
}

@media screen and (max-width: 750px) {
  .footer {
    justify-content: flex-start;
  }

  .footer .content {
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
  }

  .footerLinks {
    display: none;
  }

  .footerLinksMobile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer .brand {
    margin-left: 0;
    margin-right: 0;
  }

  .footer .brand-logo .textBold {
    font-size: 32px;
  }

  .footer .brand-logo img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .address .title {
    display: none;
  }

  .footer .address {
    margin-left: 0;
    margin-right: 0;
  }

  .address pre {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }

  .address a {
    text-align: center;
    margin-top: 0;
    font-size: 18px;

    text-decoration: none;
  }

  .social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .social p {
    font-size: 28px;
    margin-top: 3vh;
    text-align: center;
  }

  .socialIcons {
    font-size: 37px;
    text-align: center;
    justify-content: center;
    gap: 2.1vh;
  }

  .textMedium {
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .link {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .textLight {
    font-size: 16px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) and (max-height: 800px) {
  .footer {
    height: 67vh;
  }
}
