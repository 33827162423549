.features {
  margin: 0 14vw;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.features .title {
  color: #212121;
  font-size: 2.8vw;
  font-weight: 650;
  text-align: center;

  margin: 0 15vw 0;
}

.feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4vw;
  margin: 2vw 0px;
}

.desc {
  margin: 0 auto;
  color: #757575;
  font-size: 1.3vw;
  margin-bottom: 2vw;
  font-weight: 520;
}

.batches,
.receipts,
.contactForm {
  flex-direction: row-reverse;
}

.feature .content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* width: 45%; */
}

.featureTitle {
  color: #212121;

  font-size: 1.3vw;
  font-weight: 650;

  margin-bottom: 1vw;
}

.featureDesc {
  color: #757575;
  font-size: 1vw;
  font-weight: normal;
}

.features img {
  width: 43vw;
  aspect-ratio: 16/9;
  object-fit: cover;
  box-shadow: -1px 0px 3px 1px #bdbdbd;
  border-radius: 15px;
}

.featureMobile {
  display: none;
}

@media screen and (max-width: 750px) {
  .features .title {
    /* font-size: 3.3vh; */
    font-size: 33px;
    font-weight: 700;
    padding-top: 60px;
  }

  .features {
    margin: 0;
    /* padding-bottom: 20px; */
  }

  .features .desc {
    /* font-size: 2vh; */
    font-size: 21px;
    text-align: center;
    margin-top: 1.1vh;
    padding: 0 6vw;
  }

  .feature {
    display: none;
  }

  .featureMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1.5vh 0px;
  }

  .featureMobile img {
    width: 90%;
    height: 35vh;
    margin: 1vh 0 2.5vh;
  }

  .featureTitle {
    /* font-size: 2.4vh; */
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1.5vh;
  }

  .featureDesc {
    /* font-size: 2vh; */
    font-size: 21px;
    width: 84%;
  }

  .line {
    width: 60%;
    height: 1px;
    background-color: #bdbdbd;
    margin-top: 3vh;
  }

  .featureMobile:nth-child(12) .line {
    display: none;
  }
}
