@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 5px 1px #bdbdbd;
  padding: 0.6vw 2vw 0.6vw 4vw;
}

.header .brand-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header .brand-logo img {
  /* width: 2.4vw;
  height: 2.4vw; */
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.header .brand-logo span {
  font-family: "Poppins", sans-serif;
  font-size: 33px;
  font-weight: 600;
}

.header .menu .menuItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap: 40px; */
  gap: 2vw;
}

.header .menu .menuItem {
  list-style: none;
  /* font-size: 1.2vw; */
  font-size: 27px;
  font-weight: 550;
  color: #424242;

  cursor: pointer;
}

.header .menu li:hover {
  color: #0c66e4;
  font-weight: 600;
  /* text-shadow: 1px 1px 5px #0c66e433; */
}

.header .menu-icon {
  display: none;
}

.bgBlue {
  border: none;
  background-color: #0c66e4;
  color: #fff;
  /* padding: 0.6vw 1.5vw; */
  padding: 15px 30px;
  font-size: 1vw;
  font-size: 22px;
  border-radius: 50px;
  font-family: inherit;
  cursor: pointer;
  transition: 0.3s all ease;
}

.bgWhite {
  border: 4px solid #0c66e488;
  background-color: #fff;
  color: #0c66e4;
  border-radius: 50px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  /* font-size: 1vw; */
  font-size: 22px;

  /* padding: 0.45vw 2vw; */
  padding: 12px 45px;

  font-family: inherit;
}

.bgWhite:hover {
  background-color: #0c66e4;
  color: white;
}

.bgBlue:hover {
  background-color: #0c66e499;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 750px) {
  .header {
    box-shadow: unset;
    padding: 15px 25px 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
  }

  .header .menu {
    display: none;
  }

  .header .menu-icon {
    display: flex;
    cursor: pointer;

    font-size: 30px;
    color: #212121;

    /* margin-right: 10px; */
  }

  .menu-icon:hover {
    color: #212121a6;
  }

  .header .brand-logo img {
    width: 33px;
    height: 33px;
    margin-right: 2vw;
  }

  .header .brand-logo span {
    font-size: 22px;
  }
}
