.cost-summary {
  display: flex;
  justify-content: end;

  gap: 100px;

  margin-bottom: 50px;
  margin-right: 20px;
}

.cost-summary .titles {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  gap: 20px;

  color: #424242;
  font-size: 14px;
}

.cost-summary .amount {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;

  gap: 20px;

  color: #212121;
  font-size: 14px;
  font-weight: 700;
}

.cost-summary .bill-amount {
  font-size: 21px;
  font-weight: 800;
}
