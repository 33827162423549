.getStarted {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 3vw 5vw 6vw;
  min-height: fit-content;
  height: 50vw;
  max-height: 100vh;
}

.getStartedContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 3.5vw;
  color: #414a4d;
  /* width: 60%; */
}

.getStartedBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.getStartedBtn .bgBlue:hover {
  background-color: #0c66e499;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.youtube {
  /* margin-top: 0.4vw; */
  margin-right: 0.5vw;
  font-size: 1.7vw;
  /* width: 2px;
  height: 2px; */
  color: #ff1000;
  position: relative;
  top: 0.3vw;
}

.bgBlue {
  border: none;
  background-color: #0c66e4;
  color: #fff;
  padding: 0.8vw 2.3vw;
  /* font-size: 1.2vw; */
  font-size: 24px;
  font-weight: 650;
  border-radius: 50px;
  font-family: inherit;
  transition: all 0.3s ease;
}

.bgWhite {
  border: 3px solid #0c66e488;
  background-color: #fff;
  color: #0c66e4;
  border-radius: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  /* font-size: 1.2vw; */
  font-size: 24px;
  font-weight: 700;
  padding: 0.4vw 1vw;
  font-family: inherit;
  transition: all 0.3s ease;
}

.bgWhite:hover {
  background-color: #0c66e499;
  border: 3px solid #fff;
  color: white;
}

.textLight {
  color: #757575;
  /* font-size: 1.3vw; */
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 1.3vw;
}

.textBold {
  color: #424242;
  /* font-size: 3.6vw; */
  font-size: 80px;
  font-weight: 730;
  margin-bottom: 2vw;
}

.getStarted img {
  width: 40vw;
  object-fit: cover;
}

@media screen and (max-width: 750px) {
  .getStarted {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 5vh;
    margin-left: 0;
    margin-right: 0;
    /* border-bottom: 1px solid #bdbdbd; */
  }

  .textLight {
    font-size: 18px;
    /* margin-top: 2.5vh; */
    margin-top: 28px;
  }

  .textBold {
    font-size: 30px;
    width: 90%;
  }

  .getStarted img {
    width: 85%;
    object-fit: cover;
    padding-top: 40px;
  }

  .getStartedContent {
    align-items: center;
    text-align: center;
    margin-right: 0px;
  }

  .getStartedBtn {
    align-items: center;
    flex-direction: column;
    gap: 2vh;
    margin-top: 1.5vh;
  }

  .bgBlue {
    /* padding: 1.5vh 4.1vh; */
    padding: 16px 40px;
    /* font-size: 2.3vh; */
    font-size: 22px;
    font-weight: 600;
  }

  .bgWhite {
    /* padding: 1vh 2.4vh; */
    padding: 11px 20px;
    /* font-size: 2.3vh; */
    font-size: 22px;
  }

  .youtube {
    margin-right: 1vh;
    top: 5px;
    /* top: 0.5; */
    /* font-size: 3vh; */
    font-size: 30px;
  }
}
