.enquiryModal {
  border: none;
  border-radius: 10px;
  outline: none;
  margin: auto;
  padding-bottom: 2vw;
}

.enquiryModal::backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.header {
  display: none;
}

.closeModal {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 20px;
}

.closeModal .close {
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: #212121;
  border-radius: 50%;
  display: flex;
  font-size: 22px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
}

.close:hover {
  background-color: #e6e3e366;
  color: black;
}

.queryModal {
  display: flex;
  gap: 5vw;
}

/* MIDNIGHTZEN */

.enquiryModal.midnightzen {
  background-color: #212121;
  color: #fff;

  .closeModal .close {
    box-shadow: unset;
    background-color: #424242;
    color: #fff;
  }
}

.enquiryModal.midnightzen::backdrop {
  background-color: rgba(255, 255, 255, 0.27);
}

/* MINIMALIST */

.enquiryModal.minimalist {
  border-radius: unset;
  font-family: "Manrope", serif;
}

.enquiryModal.minimalist::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 750px) {
  .header {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  .enquiryModal {
    min-width: 100vw;
    min-height: 100%;
    margin: 0;
    outline: none;
    border-radius: unset;
    border: none;
    padding: 0.5vh 5vw 2vh 5vw;
  }

  .appDetailsSection {
    display: none;
  }

  .header h3 {
    font-size: 22px;
  }

  .header img {
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }

  .closeModal {
    margin-top: 15px;
    margin-right: 0;
  }

  .closeModal .close {
    font-size: 25px;
    padding: 4px;
  }
}
