.invoice-wrapper {
  background-color: #f5f5f5;
  font-family: "Nunito", serif;
}

.download-invoice {
  width: 52vw;
  min-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.invoice-wrapper .invoice-container {
  display: flex;
  flex-direction: column;
  width: 52vw;
  min-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  /* position: relative; */
}

.invoice-wrapper .download-button {
  position: relative;
  /* left: 75%; */
  /* transform: translateX(-90%); */
  font-family: "Nunito Sans", serif;
  font-size: 14px;
  font-weight: 700;
  padding: 0.4vw 1vw;
  width: 162px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  background-color: #0c66e4;
  color: white;
  border: none;
  margin-top: 4.7vh;
  margin-bottom: 2.75vh;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.block-line {
  width: 100%;
  height: 17px;
  background-color: #496ce6;
  position: relative;
  top: 0;
}

.invoice-wrapper .invoice-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 40px 50px;

  height: 100%;
}

/* LOGO-TITLE */
.invoice-wrapper .brand-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 12px;
}

.invoice-wrapper .brand-logo img {
  height: 34px;
  width: 34px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 9px;
}

.invoice-wrapper .brand-logo span {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
}

/* INVOICE NUMBER */
.invoice-wrapper .invoice-number {
  margin: 30px 0px;

  color: #212121;
  font-size: 19.5px;
  font-weight: 700;
}

/* GST & DATE */
.invoice-wrapper .basic-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 17px 0px;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;

  margin-bottom: 15px;

  color: #424242;
  font-size: 14px;
}

.invoice-wrapper .basic-info .bold {
  font-weight: 700;
  color: #212121;
}

/* FROM & TO DETAILS */
.invoice-address-div {
  display: flex;
  justify-content: space-between;

  margin-bottom: 25px;

  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.invoice-address-div .from {
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 10px;
}

.invoice-address-div .to {
  display: flex;
  flex-direction: column;
  align-items: end;

  gap: 10px;
}

.invoice-address-div .from-to {
  color: #0c66e4;
  font-weight: 600;
  font-size: 16px;
}

.invoice-address-div .name {
  font-size: 21px;
  font-weight: bold;
  color: #212121;
}

/* PAYMENT REFERENCE */
.payment-reference {
  font-size: 14px;
  /* font-weight: bold; */
  color: #424242;
}

.payment-reference span {
  color: #212121;
  font-weight: 500;
}

/* REGARDS */
.signature {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;

  margin-bottom: 20vh;
  margin-right: 20px;
}

.signature span {
  color: #424242;
  font-size: 17px;
}

.signature img {
  width: 120px;
  margin-top: 10px;
  /* object-fit: contain; */
}

/* FOOTER */
.invoice-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  justify-self: flex-end;
  background-color: #f5f5f5;
  padding: 1vw 1.5vw;
}

.invoice-footer .thank-you {
  color: #212121;
  font-weight: 700;
  font-size: 24px;
}

.invoice-footer .powered-by {
  color: #757575;
  font-weight: 400;
  font-size: 16px;
}

.invoice-wrapper {
  overflow: auto;
  position: relative;
}

.invoice-wrapper .loading {
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-wrapper .loader {
  border: 6px solid #9c9a9a;
  border-top: 6px solid #474848;

  border-radius: 50%;
  width: 57px;
  height: 57px;
  animation: spin 1.2s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
