.serviceRequest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 7vw 50px;
}

.serviceRequest .title {
  /* font-size: 1.8vw; */
  font-size: var(--section-title-font-size);
  font-weight: bold;
  text-align: center;
  color: #212121;
}

.serviceRequest .desc {
  /* font-size: 1.3vw; */
  font-size: 20px;
  color: #212121;
  text-align: center;
  line-height: 1.4;
  margin-top: 15px;
  margin-bottom: 20px;
}

.button {
  border-radius: 50px;
  border: none;
  font-family: inherit;
  align-self: center;
  font-weight: 700;
  color: white;
  background-color: var(--primary-color);
  padding: 10px 24px;
  /* font-size: 1vw; */
  font-size: var(--secondary-font-size);
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* background-color: #496ce6d9; */
  opacity: 0.5;
}

.serviceRequest.simpleui {
  font-family: "Poppins", serif;
}

/* MIDNIGHTZEN */

.serviceRequest.midnightzen {
  background-color: #000;
  color: #fff;
  font-family: "Nunito Sans", serif;
  padding: 40px 10% 50px;
  margin: unset;
}

.serviceRequest.midnightzen .title {
  color: #fff;
}

.serviceRequest.midnightzen .desc {
  color: #fff;
}

.serviceRequest.midnightzen .button {
  padding: 10px 45px;
}

/* ELEVATE */

.serviceRequest.elevate {
  font-family: "Nunito", sans-serif;

  .desc {
    font-family: "Nunito Sans", serif;
  }

  .button {
    border-radius: 6.05px;
    padding: 0;
    height: 45px;
    width: 200px;
    /* font-size: 1.2vw; */
    font-family: "Nunito Sans", serif;
  }
}

.edgefusion {
  font-family: "Inter", serif;
}

.edgefusion .title {
  font-style: italic;
  font-weight: 700;
  /* font-size: 1.9vw; */
}

.edgefusion .desc {
  font-style: italic;
  /* font-size: 1.4vw; */
  font-weight: 400;
}

.edgefusion .button {
  border-radius: unset;
  clip-path: polygon(8% 0%, 100% 0, 92% 100%, 0% 100%);
  /* font-size: 1vw; */
  font-style: italic;
  /* padding: 0.7vw 2.5vw; */
}

.serviceRequest.minimalist {
  font-family: "Manrope", serif;
}

.serviceRequest.minimalist .button {
  border-radius: unset;
  font-weight: 600;
}

.serviceRequest.minimalist .desc {
  font-size: var(--secondary-font-size);
  width: unset;
}

@media screen and (max-width: 750px) {
  .serviceRequest {
    padding: 1vh 0;
    margin: 1vh 3vw 0 3vw;
  }

  .serviceRequest .desc {
    color: #757575;
    padding: 0 5vw;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 2vh;
  }

  .button {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4vh;
    padding: 0;
    width: 60vw;
    max-width: 210px;
    height: 50px;
  }

  /* ELEVATE */

  .serviceRequest.elevate .button {
    /* padding: 3.5vw 13vw; */
    /* max-width: 240px; */
    font-size: 18px;
    border-radius: 10px;
  }

  /* EDGEFUSION */

  .serviceRequest.edgefusion {
    margin-top: 4vh;
    width: 90vw;
  }

  .serviceRequest.edgefusion .title {
    font-size: 22px;
  }

  .serviceRequest.edgefusion .desc {
    font-style: unset;
    font-size: 500;
  }

  .serviceRequest.edgefusion .button {
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
  }

  .serviceRequest.minimalist {
    font-family: "Inter", serif;
  }

  .serviceRequest.minimalist .button {
    width: 158px;
    font-size: 18px;
  }

  .serviceRequest.minimalist .desc {
    font-size: 18px;
    width: unset;
  }
}
