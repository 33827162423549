.invoice-table {
  margin: 32px 0 50px 0;
}

.invoice-table table {
  border-collapse: collapse;
  width: 100%;
}

.invoice-table th {
  color: #0c66e4;
  font-weight: bold;
  text-align: start;
  font-size: 14px;
  padding: 13px 20px;
  background-color: #f5f5f5;
  word-break: break-word;
  white-space: nowrap;
}

.invoice-table td {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 20px;
  color: #212121;
  font-size: 14px;
  text-align: start;
  font-weight: 500;
}

/* S.No */
.invoice-table th:nth-child(1),
.invoice-table td:nth-child(1) {
  width: 6.5%;
}

/* Item name */
.invoice-table th:nth-child(2),
.invoice-table td:nth-child(2) {
  width: 25%;
  text-overflow: ellipsis;
}

/* CGST */
.invoice-table th:nth-child(3),
.invoice-table td:nth-child(3) {
  width: 8%;
}

/* SGST */
.invoice-table th:nth-child(4),
.invoice-table td:nth-child(4) {
  width: 8%;
}

/* IGST */
.invoice-table th:nth-child(5),
.invoice-table td:nth-child(5) {
  width: 8%;
}

/* HSN */
.invoice-table th:nth-child(6),
.invoice-table td:nth-child(6) {
  width: 10%;
}

/* Qty */
.invoice-table th:nth-child(7),
.invoice-table td:nth-child(7) {
  width: 9%;
}

/* Rate */
.invoice-table th:nth-child(8),
.invoice-table td:nth-child(8) {
  width: 12%;
  word-break: normal;
}

/* Amount */
.invoice-table th:nth-child(9),
.invoice-table td:nth-child(9) {
  width: 15%;
  text-align: end;

  font-weight: bold;
}
