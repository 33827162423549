.contactUsModal {
  outline: none;
  border: none;
  margin: auto auto;
  padding: 30px 40px;
  border-radius: 20px;
  width: 450px;
}

.contactUsModal::backdrop {
  background-color: rgba(0, 0, 2, 0.5);
}

.close {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 20px;
}

.closeButton {
  color: #424242;
  border: none;
  padding: 5px;
  font-size: 16px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  display: flex;
  cursor: pointer;
  background-color: #fff;
}

.closeButton:hover {
  background-color: #e6e3e366;
  color: black;
}

.title {
  font-size: 21.42px;
  font-weight: 600;
  color: #0c66e4;
  margin-bottom: 5px;
}

.desc {
  font-size: 13.99px;
  color: #212121;
  margin-bottom: 20px;
}

.businessDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileNumber,
.email {
  font-size: 16.65px;
  font-weight: 400;
  margin-top: 10px;
  color: #212121;
}

.label {
  color: #8e8e8e;
  margin-top: 15px;
  font-size: 11.99px;
  font-weight: 450;
}

.email {
  text-decoration: underline;
  color: #212121;
}

.textBlue {
  color: #0c66e4;
  border: none;
  font-size: 16.65px;
  font-family: inherit;
  cursor: pointer;
  background-color: unset;
}

.textBlue:hover {
  opacity: 0.5;
}

.contactDetails.addressDetail {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
}

.address {
  border-bottom: 2px solid #bdbdbd;
  margin: 0 15px;
  padding: 10px 0;
  font-size: 13.32px;
}

.addressDetails {
  font-size: 16.65px;
  padding: 10px 30px 50px 0;
  margin: 0 15px;
}

.addressDetails span {
  line-height: 1.35;
}

@media screen and (max-width: 750px) {
  .contactUsModal {
    min-width: unset;
    padding: 20px 30px 30px;
    border-radius: 15px;
    top: 0;
    /* min-width: 100%; */
    /* min-height: 100%; */
  }

  .closeButton {
    padding: 5px;
    font-size: 18px;
  }

  .title {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: #212121;
    font-size: 24px;
  }

  .desc {
    margin-top: 25px;
    padding-right: 20px;
  }

  .label {
    font-size: 16px;
  }

  .email,
  .mobileNumber {
    font-size: 19px;
  }

  .textBlue {
    font-size: 18px;
  }

  .contactDetails.addressDetail {
    border-radius: 15px;
  }

  .address {
    color: #8e8e8e;
    font-size: 17px;
    padding: 17px 0 8px;
  }

  .addressDetails {
    font-size: 20px;
    padding-right: 10px;
  }
}
