.invoicesList {
  /* width: auto; */
  border-collapse: collapse;
  font-size: 1vw;
  padding: 0 12vw 1.5vw;
}

.invoicesList.recent {
  padding: unset;
  font-size: 0.95vw;
}

.invoicesList .headerRow,
.invoicesList .dataRow {
  display: grid;
  grid-template-columns: 0.85fr 1fr 1fr 1.25fr 2fr;
  align-items: center;
}

.invoicesList.recent .headerRow,
.invoicesList.recent .dataRow {
  grid-template-columns: 0.85fr 1fr 0.9fr 1.25fr 1.85fr;
}

.invoicesList .headerRow {
  font-weight: bold;
  font-size: 1.02vw;
  text-align: left;
  background-color: #f5f5f5;
}

.invoicesList.recent .headerRow {
  background-color: unset;
  font-size: 0.97vw;
}

.invoicesList .dataRow {
  border-bottom: 1px solid #e0e0e0;
}

.invoicesList .tableHeader,
.invoicesList .tableData {
  padding: 0.8vw 1vw;
}

.invoicesList.recent .tableHeader,
.invoicesList.recent .tableData {
  padding: 0.7vw 0.6vw;
}

.invoicesList .payment {
  display: flex;
  justify-content: start;
  align-items: center;
}

.invoicesList .payNow,
.invoicesList .downloadReceipt,
.invoicesList .pending {
  font-family: inherit;
  font-size: 0.9vw;
  font-weight: 700;
  padding: 0.45vw 1vw;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  width: 62%;
}

.invoicesList.recent .payNow,
.invoicesList.recent .downloadReceipt,
.invoicesList.recent .pending {
  font-size: 0.82vw;
  padding: 0.35vw;
  width: 75%;
}

.invoicesList .payNow {
  background-color: #0c66e4;
  color: white;
  border: none;
}

.invoicesList .downloadReceipt {
  background-color: white;
  color: #0c66e4;
  border: 2px solid #007bff;
}

.invoicesList .pending {
  background-color: #ffb700;
  color: #fff;
  border: none;
}

.invoicesList .pending:hover {
  background-color: #df9c00;
}

.invoicesList.recent .downloadReceipt {
  border: 1.5px solid #007bff;
}

.invoicesList .payNow:hover {
  background-color: #0056b3;
}

.invoicesList .downloadReceipt:hover {
  background-color: #c4c1c133;
}
