.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto 20vw;
  transform: translateY(-10%);
}

.notFoundIcon {
  color: #496ce6;
  font-size: 100px;
  text-align: center;
  position: relative;
  top: 20px;
}

.title {
  font-size: 50px;
  color: #bdbdbd;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.desc {
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  width: 55%;
  line-height: 1.5;
}

.desc span {
  display: inline-block;
}

@media screen and (max-width: 750px) {
  .page {
    margin: auto 10vw;
  }
  .desc {
    width: 100%;
  }
}
