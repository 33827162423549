.dashboard-invoice-card {
  background-color: white;
  box-shadow: 0 0 6px 1px rgb(207, 204, 204);
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 3.7vw 4.5vw 3vw;
}

.dashboard-invoice-card.recent {
  box-shadow: none;
  background-color: #f5f5f5;
}

.dashboard-invoice-card .name-inv-no-row,
.dashboard-invoice-card .date-invoice-no-row,
.dashboard-invoice-card .payment-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #424242;
  font-size: 4.1vw;
}

.dashboard-invoice-card .name-inv-no-row {
  margin-bottom: 1.8vw;
}
.dashboard-invoice-card .name {
  font-size: 4.4vw;
  font-weight: 700;
}

.dashboard-invoice-card .inv-no {
  font-weight: 500;
  color: #757575;
}

.dashboard-invoice-card .date-invoice-no-row {
  padding-bottom: 2.8vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 3.5vw;
}

.dashboard-invoice-card .amount-mop-row {
  display: flex;
  align-items: center;
  gap: 3vw;
}

.dashboard-invoice-card .mop {
  background-color: #d0e4ff;
  padding: 1.2vw 2.5vw;
  border-radius: 50px;
  font-size: 2.5vw;
  color: #212121;
  font-weight: 800;
  text-transform: uppercase;
}

.dashboard-invoice-card .payment-button {
  font-family: inherit;
  padding: 1.7vw 3vw;
  font-weight: 600;
  font-size: 3.5vw;
  border-radius: 50px;

  color: white;
  background-color: #496ce6;
  border: 2px solid #496ce6;
}

.dashboard-invoice-card .payment-button.DUE {
  color: #496ce6;
  background-color: white;
}

.dashboard-invoice-card .payment-button.pending {
  background-color: #ffb700;
  border: none;
}

.dashboard-invoice-card button:hover {
  opacity: 0.5;
}
