/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw;
  height: 100vh;
  display: block; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

/* .content div{
  margin-left: 7vw;
  margin-right: 7vw;
} */

main {
  /* height: 100vh;
  width: 100vw; */
  overflow: auto;
  position: relative;
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  /* border: 6px solid #9c9a9a;
  border-top: 6px solid #474848; */

  border: 6px solid;
  border-color: rgb(72, 72, 72) transparent rgb(72, 72, 72) transparent;

  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.2s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
