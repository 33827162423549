.dashboard-batches {
  padding: 2.5vw 18%;
}

.dashboard-batches ul {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.dashboard-batch-card {
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 6px 1px rgb(207, 204, 204);
  background-color: white;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  height: 13.5vw;
  width: 100%;
  margin-bottom: 1.4vw;
  /* aspect-ratio: 4/1.5; */
}

.dashboard-batch-card img {
  object-fit: cover;
  height: 100%;
  min-width: 33%;
  max-width: 33%;
}

.dashboard-batch-card .batch-content {
  padding: 1vw 1.25vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
  color: #212121;
  width: 100%;
}

.dashboard-batch-card .title {
  font-size: 1.12vw;
  font-weight: bold;
  text-align: start;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dashboard-batch-card hr {
  margin: 0.25vw 0 0.6vw;
  border: 0.1px solid #496ce6;
  opacity: 0.7;
  width: 100%;
}

.dashboard-batch-card .desc {
  font-size: 0.93vw;
  text-align: start;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dashboard-batch-card .timing {
  background-color: #f5f5f5;
  padding: 0.4vw 1vw;
  border-radius: 10px;
  margin: 0.75vw 0 1.1vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  font-size: 0.9vw;
}

.dashboard-batch-card .clock-icon {
  color: #496ce6;
}

.dashboard-batch-card .timing span {
  font-weight: bold;
}

.dashboard-batch-card button {
  border-radius: 50px;
  border: none;
  font-family: inherit;
  font-weight: 600;
  color: white;
  background-color: #496ce6;
  padding: 0.5vw 1.7vw;
  font-size: 0.9vw;
  text-transform: uppercase;
  cursor: pointer;
}

.dashboard-batch-card button:hover {
  background-color: #496ce6d9;
}

@media screen and (max-width: 750px) {
  .dashboard-batch-card {
    border-radius: 7px;
    height: 48vw;
  }

  .dashboard-batch-card.recent {
    box-shadow: none;
    background-color: #f5f5f5;
  }

  .dashboard-batch-card img {
    width: 33%;
  }

  .dashboard-batch-card .batch-content {
    padding: 2.8vw;
  }

  .dashboard-batch-card .title {
    font-size: 4.2vw;
  }

  .dashboard-batch-card hr {
    margin: 1.5vw 0;
    border: 1px solid #496ce6cc;
  }

  .dashboard-batch-card .desc {
    font-size: 3.15vw;
    line-height: 1.5;

    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .dashboard-batch-card .timing {
    font-size: 3vw;
    padding: 0.8vw 2vw;
    margin: 1.5vw 0 3.5vw;
    gap: 1.1vw;
  }

  .dashboard-batch-card.recent .timing {
    background-color: white;
  }

  .dashboard-batch-card button {
    font-weight: 600;
    padding: 1.3vw 3.5vw;
    font-size: 3.5vw;

    text-transform: unset;
  }

  .dashboard-batches {
    padding: 0;
    margin-top: 2vw;
  }

  .dashboard-batches ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 4vw;
  }
}
