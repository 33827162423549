@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.section {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vw;
  margin-top: 5vw;
}

.app .image {
  width: 1.9vw;
  height: 1.9vw;
  text-align: center;
  margin-right: 0.3vw;
}

.app {
  display: flex;
  text-align: center;
  align-items: center;
  color: #212121;
  font-weight: 500;
  margin: 0 1vw;
}

/* .textMedium {
  display: flex;
} */

.text {
  color: #757575;
  font-size: 1.85vw;
  font-weight: 500;
  padding-top: 3vw;
  margin-bottom: 1vw;
  text-align: center;
  display: flex;
  align-items: center;
}

.textBold {
  font-size: 4.4vw;
  font-weight: 900;
  font-family: "Inter", serif;
  color: #0c66e4;
  margin-bottom: 3vw;
  letter-spacing: 1px;
}

@media screen and (max-width: 750px) {
  .section {
    margin-top: 2vh;
    margin-bottom: 0;
  }

  .text {
    font-size: 2.1vh;
    margin: 0 5vw 10px;
  }

  .app .image {
    width: 28px;
    height: 28px;
  }

  .textBold {
    font-size: 30px;
  }
}
